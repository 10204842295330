@media print {
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }
    .check {
      width: 100%;
      padding: 20px;
    }
    .CheckImg {
      width: 100%;
      max-width: 250px;
      height: auto;
      padding: 10px;
      margin: 0 auto;
      display: block;
      border-radius: 8px;
    }
    .check_list {
      list-style-type: none;
      padding: 0;
    }
    .check_list li {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
    }
    .check_list > li:last-child {
      border-bottom: 1px dashed #000;
    }
    .check_list li div{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .check_list li div span:first-child{
     font-weight: 700;
    }
    .check_list li span {
      font-size: 12px;
    }
  }