/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap"); */
/* Require Editor CSS files. */
@import url("froala-editor/css/froala_style.min.css");
@import url("froala-editor/css/froala_editor.pkgd.min.css");

body {
  font-family: "Gilroy";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-SemiBold.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "Gilroy" !important;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.ant-notification-notice {
  background-color: #00afff;
  color: #fff;
}
.ant-notification-notice .password {
  color: #ed4337 !important;
  display: inline-block !important;
  cursor: pointer !important;
}
.ant-notification-notice svg path {
  fill: #fff;
}
.ant-notification-notice-message {
  color: #fff !important;
}
.page {
  width: 100%;
  /* background-color: white; */
  /* padding: 15px; */
  /* border-radius: 6px; */
}
.error-text {
  color: #ed4337;
}
.blue-text {
  color: #00afff !important;
}

.froala-editor-container {
  /* width: 20.1cm !important; */
  margin: auto;
}
.fr-wrapper {
  padding: 0 40px !important;
}
.fr-wrapper .fr-element {
  padding: 0 40px !important;
}
@media print {
  .froala-editor-container {
    margin: 0 40px !important;
    padding: 0 40px !important;
  }
  .fr-wrapper {
    padding: 0 40px !important;
  }
  .fr-wrapper .fr-element {
    padding: 0 40px !important;
  }
}

.froala-editor-container .fr-second-toolbar a {
  visibility: hidden;
}
.fr-wrapper > div:first-child > a:first-child {
  visibility: hidden !important;
}
p[data-f-id="pbf"] {
  display: none;
  visibility: hidden !important;
}

@media print {
  .no-border th,
  .no-border td {
    border: none !important;
  }
  .fr-view * {
    color: #000 !important;
    /* border-color: #000 !important; */
  }
  .contract-report-table * {
    text-align: center;
    font-size: 13px !important;
  }
  .contract-report-table .car-name-info {
    padding: 5px;
  }
  .contract-report-table .car-name-info div {
    text-align: left !important;
  }
  .contract-report-table .header {
    padding: 5px 0;
  }
}
.no-border th,
.no-border td {
  border: none !important;
}
.fr-view * {
  color: #000 !important;
  /* border-color: #000 !important; */
}

.contract-report-table * {
  text-align: center;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 15px;
}
.contract-report-table .car-name-info div {
  text-align: left !important;
}
.contract-report-table .car-name-info {
  padding: 5px;
}
.contract-report-table .header > td {
  padding: 5px 0;
}
/*    */
